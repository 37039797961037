import mainStyle from './styles/main.css?inline'
import domHeadStyle from './styles/dom-head.css?inline'
import type { WidgetSettings } from './models/models'
import SvelteApp from './components/core/app.svelte'

export class ChatWidget {
  mainDomElement!: HTMLDivElement
  shadowHost!: ShadowRoot

  constructor(widgetSettings: WidgetSettings) {
    this.createMainElement()
    this.createShadowHost()
    this.addHeadStyle()
    this.addMainCss()

    const svelteApp = new SvelteApp({
      target: this.shadowHost,
      props: { widgetSettings, shadowHost: this.shadowHost },
    })

    // svelteApp.$destroy()
  }

  createMainElement() {
    this.mainDomElement = document.createElement('div')
    this.mainDomElement.classList.add('dontasq-chat-widget')
    document.documentElement.appendChild(this.mainDomElement)
  }
  createShadowHost() {
    this.shadowHost = this.mainDomElement.attachShadow({ mode: 'closed' })
  }
  addHeadStyle() {
    const headStyle = document.createElement('style')
    headStyle.innerHTML = domHeadStyle
    document.head.appendChild(headStyle)
  }
  addMainCss() {
    const mainCss = document.createElement('style')
    mainCss.innerHTML = mainStyle
    this.shadowHost.appendChild(mainCss)
  }
}

if (window.Dontasq) {
  window.Dontasq.ChatWidget = ChatWidget
} else {
  window.Dontasq = {
    ChatWidget,
  }
}
