import { writable } from 'svelte/store'
import { get } from 'svelte/store'
import { settingsStore } from './settings-store'
import { LocalStorageKeyName } from '../base/constants'

function createLocalStorageStore() {
  const { subscribe, set } = writable(localStorage)

  return {
    subscribe,
    set: (key: string, value: string) => {
      if (value === null) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, value)
      }
      set(JSON.parse(JSON.stringify(localStorage)))
    },

    get: (key: string) => localStorage.getItem(key),

    getStorageKey: (key: string) => {
      const apiKey = get(settingsStore).apiKey
      return `${key}:${apiKey}`
    },

    getIsChatWidgetOpen: () => {
      const storageKey = localStorageStore.getStorageKey(LocalStorageKeyName.isChatWindowOpen)
      return localStorageStore.get(storageKey) === 'true'
    },
    setIsChatWidgetOpen: (value: boolean) => {
      const storageKey = localStorageStore.getStorageKey(LocalStorageKeyName.isChatWindowOpen)
      localStorageStore.set(storageKey, value.toString())
    },

    getAnonymousAuthToken: () => {
      const storageKey = localStorageStore.getStorageKey(LocalStorageKeyName.anonymousAuthToken)
      return localStorageStore.get(storageKey)
    },
    setAnonymousAuthToken: (value: string) => {
      const storageKey = localStorageStore.getStorageKey(LocalStorageKeyName.anonymousAuthToken)
      localStorageStore.set(storageKey, value)
    },
  }
}

export const localStorageStore = createLocalStorageStore()
