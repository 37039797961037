import { type Conversation, type Message, type WidgetSettings } from './models'

export const widgetSettingsDefault: WidgetSettings = {
  apiKey: '',
  jwtToken: '',
  chatBubbleIcon: '',
  googleFont: 'Albert Sans',
}

export const conversationDefault: Conversation = {
  id: 0,
  assistant_name: '',
  created_at: 0,
  ended_at: 0,
  messages: [],
}

export const messageDefault: Message = {
  id: 0,
  content: '',
  role: 'user',
  created_at: 0,
}
