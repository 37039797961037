import { writable, get } from 'svelte/store'
import type { Writable } from 'svelte/store'
import { sleep } from '../base/misc'
import type { EventName } from '../base/types'

interface EventListenerCallback<DetailType> {
  (detail: DetailType): void
}

interface EventListeners {
  [key: string]: { id: number; callback: EventListenerCallback<any> }[]
}

const createEventsStore = () => {
  const events: Writable<EventListeners> = writable({})
  let listenerIdCounter = 0

  function subscribe<DetailType>(
    eventName: EventName,
    callback: EventListenerCallback<DetailType>
  ) {
    const id = listenerIdCounter++
    events.update(allEvents => {
      const listeners = allEvents[eventName] || []
      return {
        ...allEvents,
        [eventName]: [...listeners, { id, callback: callback as EventListenerCallback<any> }],
      }
    })

    const unsubscribe = () => {
      events.update(allEvents => {
        const filteredListeners = allEvents[eventName].filter(listener => listener.id !== id)
        return { ...allEvents, [eventName]: filteredListeners }
      })
    }

    return unsubscribe
  }

  async function dispatch<DetailType>(eventName: EventName, detail?: DetailType, timeout?: number) {
    const allEvents = get(events)
    const listeners = allEvents[eventName] || []

    await sleep(timeout || 0)
    listeners.forEach(listener => listener.callback(detail))
  }

  return { subscribe, dispatch }
}

export const eventsStore = createEventsStore()
