import { MOBILE_BREAKPOINT } from './constants'

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function getEmojiFromCode(code: number): string {
  return String.fromCodePoint(code)
}

export function isMobileView() {
  return window.innerWidth <= MOBILE_BREAKPOINT
}

export function escapeHTML(str: string) {
  return str
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/&/g, '&amp;')
    .replace(/'/g, '&#39;')
}

export function spanAroundEmoji(text: string) {
  const emojiRegex =
    /([\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E0}-\u{1F1FF}])/gu
  return text.replace(emojiRegex, '<span class="emoji">$1</span>')
}
