import { isMobileView } from './misc'

export class SizeAdjustments {
  _isChatWindowOpen = false

  set isChatWindowOpen(value: boolean) {
    this._isChatWindowOpen = value
  }
  get isChatWindowOpen() {
    return this._isChatWindowOpen
  }

  constructor(public chatWindow: HTMLElement) {}

  onVisualViewportResize(event: Event) {
    this.toggleMainBodyAlteration()
    this.toggleMobileKeyboardState(event)
  }

  toggleMainBodyAlteration() {
    if (isMobileView() && this.isChatWindowOpen) {
      document.body.classList.add('dontasq-hidden')
      window.addEventListener('scroll', this.preventScroll, false)
      this.insertMetaElement()
    } else {
      document.body.classList.remove('dontasq-hidden')
      window.removeEventListener('scroll', this.preventScroll, false)
      this.removeMetaElement()
    }
  }

  insertMetaElement() {
    if (document.querySelector('.dontasq-meta')) return

    const meta = document.createElement('meta')
    meta.name = 'viewport'
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1'
    meta.classList.add('dontasq-meta')
    document.head.appendChild(meta)
  }

  removeMetaElement() {
    const meta = document.querySelector('.dontasq-meta')
    if (meta) meta.remove()
  }

  preventScroll(e: Event) {
    e.preventDefault()
    window.scrollTo(0, 0)
  }

  toggleMobileKeyboardState(event: Event) {
    const windowHeight = window.innerHeight
    const target = event.target as VisualViewport
    const visualHeight = target.height * target.scale
    const isKeyboardOpen = visualHeight < windowHeight
    if (isKeyboardOpen && isMobileView()) {
      this.chatWindow.style.top = '0px'
      this.chatWindow.style.height = visualHeight + 'px'
    } else {
      this.chatWindow.style.removeProperty('top')
      this.chatWindow.style.removeProperty('height')
    }
  }
}
