<script lang="ts">
  import { onMount } from 'svelte'
  import { chatBubbleIcon1 } from '../../svg/bubble-icons/chat-bubble-icon-1-svg'
  import { SizeAdjustments } from '../../base/size-adjustments'
  import { sleep } from '../../base/misc'
  import type { WidgetSettings } from '../../models/models'
  import Loader from '../ui/loader.svelte'
  import { widgetSettingsDefault } from '../../models/defaults'
  import { settingsStore } from '../../stores/settings-store'
  import { localStorageStore } from '../../stores/local-storage-store'
  import type { SvelteComponentType } from '../../base/types'
  import { eventsStore } from '../../stores/events-store'

  export let widgetSettings: WidgetSettings
  export let shadowHost: ShadowRoot

  widgetSettings = { ...widgetSettingsDefault, ...widgetSettings }
  settingsStore.set(widgetSettings)

  let isChatWindowOpen: boolean
  let sizeAdjustments: SizeAdjustments
  let chatWindow: HTMLElement
  let windowComponent: SvelteComponentType
  let menuComponent: SvelteComponentType
  let isMenuOpen: boolean
  let chatBubbleIcon: string

  $: if (sizeAdjustments) {
    sizeAdjustments.isChatWindowOpen = isChatWindowOpen
  }

  function setChatBubbleIcon() {
    chatBubbleIcon = widgetSettings.chatBubbleIcon || chatBubbleIcon1
  }

  function setInitialState() {
    const isChatWindowOpenInLocalStorage = localStorageStore.getIsChatWidgetOpen()
    if (isChatWindowOpenInLocalStorage) {
      openChatWindow()
    }
  }
  function addSizeAdjustments() {
    sizeAdjustments = new SizeAdjustments(chatWindow)
    window.visualViewport?.addEventListener(
      'resize',
      sizeAdjustments.onVisualViewportResize.bind(sizeAdjustments)
    )
  }

  function toggleChatWindow() {
    isChatWindowOpen ? closeChatWindow() : openChatWindow()
  }

  async function openChatWindow() {
    isChatWindowOpen = true
    localStorageStore.setIsChatWidgetOpen(true)
    await sleep(0)
    sizeAdjustments.toggleMainBodyAlteration()

    if (!windowComponent) {
      windowComponent = (await import('./window.svelte')).default
    }

    eventsStore.dispatch('textarea-focus', null, 100)
  }

  async function closeChatWindow() {
    isChatWindowOpen = false
    localStorageStore.setIsChatWidgetOpen(false)
    await sleep(0)
    sizeAdjustments.toggleMainBodyAlteration()
  }

  function toggleMenu() {
    isMenuOpen ? closeMenu() : openMenu()
  }

  async function openMenu() {
    isMenuOpen = true
    if (!menuComponent) {
      menuComponent = (await import('../menu/menu.svelte')).default
    }
  }

  function closeMenu() {
    isMenuOpen = false
  }

  function onWindowClick() {
    if (isMenuOpen) {
      closeMenu()
    }
  }

  onMount(() => {
    setChatBubbleIcon()
    setInitialState()
    addSizeAdjustments()
  })
</script>

<div class="widget" class:active={isChatWindowOpen}>
  <button class="bubble" on:click={toggleChatWindow}>
    <span class="bubble-icon">{@html chatBubbleIcon}</span>
  </button>
  <div class="window full-window" bind:this={chatWindow} class:menu-open={isMenuOpen}>
    <div class="menu-component">
      {#if menuComponent}
        <svelte:component this={menuComponent} on:menu={toggleMenu} />
      {:else}
        <Loader fullWidth fullHeight />
      {/if}
    </div>

    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div class="window-component full-window" on:click={onWindowClick}>
      <div class="overlay full-window">
        {#if windowComponent}
          <svelte:component
            this={windowComponent}
            on:close={closeChatWindow}
            on:menu={toggleMenu}
            {shadowHost}
          />
        {:else}
          <Loader fullWidth fullHeight />
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .widget {
    font-size: 16px;
    position: fixed;
    z-index: 2147483647;
    right: 1.5em;
    bottom: 1.5em;
    user-select: none;
    color: var(--text-color);
  }
  .full-window {
    width: 100vw;
    height: 100vh;
    max-width: 24em;
    max-height: 42em;
  }
  .window {
    background-color: var(--background-color);
    border-radius: 1em;
    position: relative;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
    overflow: hidden;
    /* border: 1px solid var(--border-color); */
    display: none;
    transform-origin: bottom right;
    animation:
      /* slideout 0.25s, */
      flyout 0.25s,
      scaleout 0.25s,
      fadeout 0.2s;
  }
  .widget.active .window {
    animation:
      /* slidein 0.25s, */
      flyin 0.25s,
      scalein 0.25s,
      fadein 0.2s;
    display: block;
  }

  .bubble {
    width: 4em;
    height: 4em;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    cursor: pointer;
    border-radius: 4em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
    transition: transform 0.2s;
  }

  .bubble-icon {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .bubble:hover {
    transform: scale(1.1);
  }
  .bubble:active {
    transform: scale(1);
  }

  .menu-component {
    position: absolute;
    width: 80%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.25s;
    display: flex;
  }
  .window-component {
    position: absolute;
    transition:
      transform 0.25s,
      filter 0.25s;
  }

  .window.menu-open .menu-component {
    transform: translateX(0);
  }
  .window.menu-open .window-component {
    transform: translateX(80%);
    filter: blur(0.7px);
    cursor: pointer;
  }
  .window.menu-open .window-component .overlay {
    pointer-events: none;
  }

  @media (max-width: 40em) {
    .widget {
      font-size: 18px;
    }
    .widget.active {
      right: 0;
      bottom: 0;
    }
    .widget.active .bubble {
      display: none;
    }
    .full-window {
      height: 100%;
      width: 100%;
      max-width: none;
      max-height: none;
    }
    .window {
      position: fixed;
      border-radius: 0;
      margin: 0;
      top: 0;
      left: 0;
    }
  }
</style>
