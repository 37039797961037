import type { Environment } from './types'

export function getEnvironment(): Environment {
  // Force production environment
  // return 'production'

  const currentScriptSrc = import.meta?.url

  const devOrigins = ['localhost', '127.0.0.1', '192.168.', 'ngrok']

  return devOrigins.some(origin => currentScriptSrc?.includes(origin))
    ? 'development'
    : currentScriptSrc?.includes('staging')
    ? 'staging'
    : 'production'
}

export const ENVIRONMENT = getEnvironment()
