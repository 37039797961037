<script lang="ts">
  export let fullWidth: boolean = false
  export let fullHeight: boolean = false
</script>

<div class="fade-in" class:full-width={fullWidth} class:full-height={fullHeight}>
  <div class="circles">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<style>
  .fade-in {
    animation: delayedfadein 1s;
  }
  .full-width {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .full-height {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circles,
  .circles div {
    font-size: 10px;
    box-sizing: border-box;
  }
  .circles {
    display: inline-block;
    position: relative;
    width: 4.5em;
    height: 2em;
  }
  .circles div {
    position: absolute;
    top: 0.675em;
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    background-color: var(--text-color-2);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .circles div:nth-child(1) {
    left: 0.5em;
    animation: scale-up 0.6s infinite;
  }
  .circles div:nth-child(2) {
    left: 0.5em;
    animation: move-right 0.6s infinite;
  }
  .circles div:nth-child(3) {
    left: 2em;
    animation: move-right 0.6s infinite;
  }
  .circles div:nth-child(4) {
    left: 3.5em;
    animation: scale-down 0.6s infinite;
  }
  @keyframes scale-up {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes scale-down {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes move-right {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(1.5em, 0);
    }
  }
</style>
