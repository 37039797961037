import { ENVIRONMENT } from './environment'
import type { Dictionary, Environment } from './types'

console.log('environment:', ENVIRONMENT)

export const DONTASQ_HOME_URL = 'https://dontasq.com'

const _DONTASQ_CHAT_API_URL: Dictionary<Environment, string> = {
  production: 'https://api.dontasq.com',
  staging: 'https://api-staging.dontasq.com',
  development: 'http://localhost:7050',
}
export const DONTASQ_CHAT_API_URL = _DONTASQ_CHAT_API_URL[ENVIRONMENT]

export const MOBILE_BREAKPOINT = 640

export enum LocalStorageKeyName {
  isChatWindowOpen = 'Dontasq:is-chat-window-open',
  anonymousAuthToken = 'Donasq:anonymous-auth-token',
}

export enum builtInChatBubbleIcons {
  chatBubbleIcon1 = 'chatBubbleIcon1',
  chatBubbleIcon2 = 'chatBubbleIcon2',
  chatBubbleIcon3 = 'chatBubbleIcon3',
  chatBubbleIcon4 = 'chatBubbleIcon4',
  chatBubbleIcon5 = 'chatBubbleIcon5',
  chatBubbleIcon6 = 'chatBubbleIcon6',
  chatBubbleIcon7 = 'chatBubbleIcon7',
  chatBubbleIcon8 = 'chatBubbleIcon8',
  chatBubbleIcon9 = 'chatBubbleIcon9',
  chatBubbleIcon10 = 'chatBubbleIcon10',
}
